import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Avatar, useChatContext } from 'stream-chat-react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CreateChannelIcon } from '../../assets';
import streamLogo from '../../assets/stream.png';

import type { StreamChatGenerics } from '../../types';

type Props = {
  onCreateChannel?: () => void;
};

const MessagingChannelListHeader = React.memo((props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onCreateChannel } = props;

  const { client } = useChatContext<StreamChatGenerics>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, image = streamLogo as string, name = 'Example User' } = client.user || {};

  console.log(name)

  return (
      <div className='messaging__channel-list__header'>
        {/* <Avatar image={image} name={name} size={40} /> */}
        <div className={`messaging__channel-list__header__name`}>{name || id}</div>
        {/* <button
          className={`messaging__channel-list__header__button`}
          onClick={onCreateChannel}
        >
          <CreateChannelIcon />
        </button> */}
      </div>
  );
});

export default React.memo(MessagingChannelListHeader);
